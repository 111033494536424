<template>
  <v-card
    :elevation="elevation"
    class="rounded-circle pa-0"
    :width="chipSize"
    :height="chipSize"
    :max-height="chipSize"
    :to="to"
  >
    <div class="w-full h-full flex items-center justify-center" no-gutters>
      <logo :size="size" :color="color" :lucid="lucid" />
    </div>
  </v-card>
</template>

<script>
import Logo from "./Logo";
export default {
  components: { Logo },
  props: {
    elevation: { default: 2 },
    size: { default: "50" },
    to: { type: Object, default: () => ({ name: "index" }) },
    color: { type: String },
    lucid: { type: Boolean, default: true },
  },
  computed: {
    chipSize() {
      return Number(this.size) + 4;
    },
  },
};
</script>
